import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { Switch, BrowserRouter as Router, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

import ExpirationModal from "components/TP/authentication/expirationPopUp";
import { Toaster } from "react-hot-toast";

import { logoutUser } from "store/actions";
import { ConfirmationModal } from "components/TP/Common/Modals";
import ScrollToTopButton from "components/TP/Common/ScrollToTopButton";
import WhattsappChatIcon from "components/TP/Claims/WhattsappChatIcon";
import { isUserAuthinticated } from "utils";

const App = (props) => {
  const [showExpiry, setShowExpiry] = useState(false);
  const { isModal } = useSelector((state) => ({
    isModal: state.ToggleModal.isModal,
  }));

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      console.log = () => {};
    }
    checkLoggedIn();
  }, []);

  const checkLoggedIn = () => {
    const expiryTime = JSON.parse(localStorage.getItem("accessToken"));
    if (expiryTime) {
      const timeRemaining = new Date(expiryTime.expires_in) - new Date();
      if (timeRemaining > 0) {
        setTimeout(() => {
          setShowExpiry(true);
        }, new Date(expiryTime.expires_in) - new Date());
      } else {
        dispatch(logoutUser(history));
      }
    }
  };

  const Layout = VerticalLayout;

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
      <ExpirationModal
        show={showExpiry}
        onCloseClick={() => setShowExpiry(false)}
      />
      {isModal && <ConfirmationModal />}
      <Toaster />

      <ScrollToTopButton />
      <WhattsappChatIcon />
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
