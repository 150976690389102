import React, { Fragment } from "react";
import { getMainProvider } from "utils";
import WhatssappIcon from "assets/images/icons/whatsapp-icon-logo.svg";

const WhattsappChatIcon = () => {
  const sendWatts = () => {
    const mainProvider = getMainProvider();

    const mobileNumber = "96181300829";
    // Regex expression to remove all characters which are NOT alphanumeric
    let num = mobileNumber;

    // Appending the phone number to the URL
    let url = `https://web.whatsapp.com/send?phone=${num}`;

    const message = mainProvider
      ? `On behalf of ${mainProvider.first_name} (${mainProvider.id}) I need your support`
      : ``;
    // Appending the message to the URL by encoding it
    url += `&text=${encodeURI(message)}&app_absent=0`;

    // Open our newly created URL in a new tab to send the message
    window.open(url);
  };

  return (
    <Fragment>
      {/* <span className="whattsapp-chat-title">Technical Support</span> */}
      <button
        type="button"
        className="whattsapp-chat-icon"
        title="Chat"
        data-toggle="tooltip"
        onClick={() => sendWatts()}
      >
        <img src={WhatssappIcon} alt="Whattsapp Icon" />
      </button>
    </Fragment>
  );
};

export default WhattsappChatIcon;
