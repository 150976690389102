import { buttonsTitles, claimStatus, staticFieldLabels } from "common/data"
import { AsyncSearchSelect } from "components/TP/Common/Forms"
import { SingleRowAction, StickyHeaderTable } from "components/TP/Common/Tables"
import { getAllClaims } from "helpers/Claims/ClaimForm"
import { isEqual, uniqBy } from "lodash"
import React, { Fragment, useEffect, useMemo, useState } from "react"
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { Col, Row } from "reactstrap"
// Formik validation

import { CLAIMFORM } from "helpers/url_helper"

import {
  NotificationMessage,
  apiErrorrHandler,
  fieldFinder,
  isInputInvalid,
  objectToValue,
  removeFalsyObjectValues,
} from "utils"
import DateRangePicker from "../Common/Forms/DateRangePicker"
import moment from "moment"
import { previewDateFormat } from "constants/TPA"
import { CSVLink } from "react-csv"
import { exportToExcel } from "utils/exportExcel"

const InnerClaimsTable = ({
  validation,
  oldClaims,
  claimForms,
  setClaimForms,
  batchClaim,
  t,
}) => {
  // descturcture validation object
  const { values, setFieldValue } = validation
  // Batch Claims Permission Level 3 states
  const {
    fields: inputs,
    name,
    loading,
  } = useSelector((state) => ({
    fields: state.Module.fields,
    name: state.Module.name,
    loading: state.Module.loading,
  }))

  const [isLoading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState({})

  const [dateRange, setDateRange] = useState({
    start_date: "",
    end_date: "",
  })

  const location = useLocation()
  // if it has a value (this means we are in update page)
  const recordId = location.pathname.split("/")[3]
  const authUser = JSON.parse(localStorage.getItem("authUser"))

  const getClaimFroms = async ({
    page,
    per_page,
    sort_by,
    sort_type,
    search_value,
    search_keys,
    search_values,
    claim_ids,
  }) => {
    if (
      isEqual(
        claim_ids,
        claimForms.map((item) => item.id)
      )
    ) {
      NotificationMessage("Error", "The Data is Already here")
      return
    }
    try {
      setIsLoading(true)
      const res = await getAllClaims({
        page: 1,
        per_page: 100,
        sort_by,
        sort_type,
        search_value,
        search_keys,
        search_values,
        claim_ids: claim_ids,
      })

      // setClaimForms(unionBy(claimForms, res.items, "id"))
      setClaimForms(res.items)
      setPagination({
        size: res.size,
        page: res.page,
        per_page: res.per_page,
        total_pages: res.total_pages,
        total: res.total_size,
      })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    }
  }

  // table columns
  const columns = useMemo(() => {
    if (inputs.length > 0) {
      return [
        "#",
        {
          id: 435,
          label: fieldFinder(inputs, 435)?.label_name,
          key: fieldFinder(inputs, 435)?.index_name,
        },
        {
          id: 436,
          label: fieldFinder(inputs, 436)?.label_name,
          key: fieldFinder(inputs, 436)?.index_name,
        },
        // {
        //   id: 437,
        //   label: fieldFinder(inputs, 437)?.label_name,
        // },
        {
          id: 438,
          label: fieldFinder(inputs, 438)?.label_name,
          key: fieldFinder(inputs, 438)?.index_name,
        },
        {
          id: 439,
          label: fieldFinder(inputs, 439)?.label_name,
          key: fieldFinder(inputs, 439)?.index_name,
        },
        {
          id: 440,
          label: fieldFinder(inputs, 440)?.label_name,
          key: fieldFinder(inputs, 440)?.index_name,
        },
        // {
        //   id: 441,
        //   label: fieldFinder(inputs, 441)?.label_name,
        // },
        {
          id: 442,
          label: fieldFinder(inputs, 442)?.label_name,
          key: fieldFinder(inputs, 442)?.index_name,
        },
        // {
        //   id: 443,
        //   label: fieldFinder(inputs, 443)?.label_name,
        // },
        {
          id: 996,
          label: fieldFinder(inputs, 996)?.label_name,
          key: fieldFinder(inputs, 996)?.index_name,
        },
        buttonsTitles.actions,
      ]
    } else {
      return []
    }
  }, [inputs])

  // empty the claims after date change
  useEffect(() => {
    if (!recordId) {
      setClaimForms([])
      setFieldValue("claims", [])
    }
  }, [dateRange.start_date, dateRange.end_date, recordId])

  const CSVHeaders = columns
    ?.filter((item) => item.label && item.key)
    .map((item) => ({
      label: item.label,
      key:
        item.key === "claim.reference"
          ? "reference"
          : item.key === "claims.status.name"
          ? "status.name"
          : item.key,
    }))
  const CSVData = [
    ...claimForms,
    {},
    //total amount
    {
      total_claimed_amount: claimForms.reduce(
        (total, claim) => total + (claim?.total_claimed_amount || 0),
        0
      ),
    },
  ]

  return (
    <div>
      <Row>
        {/* in add screen only */}
        {!recordId && (
          <Col md={2}>
            <DateRangePicker
              label={staticFieldLabels.fromToDate}
              value={dateRange}
              setDateRange={setDateRange}
            />
          </Col>
        )}
        <Col md={recordId ? 12 : 10}>
          <AsyncSearchSelect
            id="1184"
            name="claims"
            inputField={fieldFinder(inputs, 1184)}
            value={values.claims}
            onChange={setFieldValue}
            onSelectData={(data) => {
              if (data.length > 0) {
                const combinedClaimIds =
                  oldClaims && oldClaims?.length > 0
                    ? [
                        ...data.map((item) => item.id),
                        ...oldClaims?.map((item) => item.id),
                      ]
                    : [...data.map((item) => item.id)]
                getClaimFroms({
                  claim_ids: combinedClaimIds,
                })
                setFieldValue(
                  "claims",
                  oldClaims && oldClaims?.length > 0
                    ? uniqBy([...data, ...oldClaims], "id")
                    : [...data]
                )
              } else {
                setClaimForms(
                  oldClaims && oldClaims.length > 0 ? oldClaims : []
                )
                setFieldValue("claims", [])
              }
            }}
            onBlur={validation.setFieldTouched}
            mappingValue="id"
            mappingLabel="reference"
            disableSorting={true}
            generateCustomLabels={(item) =>
              `${item.bar_code} | ${item.reference} | ${item.beneficiary?.name}`
            }
            url={CLAIMFORM}
            searchKey="bar_code,reference,beneficiary.name"
            ORSearch={true}
            disableFetch={!values.healthcare_provider_id ? true : false}
            getOptionsData={getAllClaims}
            dynamicQuery={
              recordId
                ? {
                    healthcare_provider_ids: [
                      objectToValue(values.healthcare_provider_id, "id"),
                    ],
                    payer_id: values.payer_id,
                  }
                : removeFalsyObjectValues({
                    healthcare_provider_ids: [
                      objectToValue(values.healthcare_provider_id, "id"),
                    ],
                    payer_id: values.payer_id,
                    from_admission_date: dateRange.start_date.replaceAll(
                      "/",
                      "-"
                    ),
                    to_admission_date: dateRange.end_date.replaceAll("/", "-"),
                    benefit_id: values.benefit_id,
                    search_keys: values.is_Er ? "class.slug" : "",
                    search_values: values.is_Er ? "class-er" : "",
                  })
            }
            urlAddOns={{
              status_ids: [claimStatus.returned, claimStatus.approved],
              branch_id: authUser?.branch?.id,
            }}
            customPagination={100}
            invalid={isInputInvalid(validation, "claims")}
            error={validation.errors.claims}
            multiple={true}
            hideSelectedOptions={false}
            wrapperClassNames="mb-3"
            selectAll={true}
            fixedMenu={true}
          />
        </Col>
      </Row>

      {/* Table */}
      <div className=" d-flex gap-2 align-items-end justify-content-end">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            exportToExcel({
              headers: CSVHeaders,
              data: CSVData,
              filename: "claim_batch",
            })
          }}
        >
          {buttonsTitles.exportExcel}
        </button>
      </div>
      <StickyHeaderTable
        columns={columns}
        isLoading={isLoading || loading ? true : false}
        noData={claimForms?.length === 0 ? true : false}
      >
        {claimForms.length > 0 &&
          claimForms.map((claimForm, index) => (
            <Fragment key={claimForm.id}>
              <tr key={claimForm.id}>
                <td>{index + 1}</td>
                {/* service date */}
                <td>
                  {claimForm.admission_date
                    ? moment(claimForm.admission_date).format(previewDateFormat)
                    : ""}
                </td>
                {/* reference */}
                <td>{claimForm?.reference}</td>
                {/* Patient card Number */}
                <td>{claimForm?.beneficiary?.code}</td>
                {/* Patient name */}
                <td>{claimForm?.beneficiary?.name}</td>
                {/* FOB */}
                <td>{claimForm.benefit.name}</td>

                {/* total claimed */}
                <td>
                  {claimForm?.total_claimed_amount?.toLocaleString("en-US")}
                </td>
                {/* claim Status */}
                <td>{claimForm?.status?.name}</td>
                {recordId ? (
                  <td>
                    {/* in batch claim (claim tables hide the delete button if the claim is not received) */}
                    {/* or in update batch claim and batch claim status is received */}
                    <div className="d-flex align-items-center gap-1">
                      {([
                        "claimStatus_received",
                        "claimStatus_approved",
                        "claimStatus_returned",
                      ].includes(claimForm?.status?.slug) ||
                        batchClaim?.status?.slug ===
                          "batchClaimStatus-received") && (
                        <SingleRowAction
                          color="text-danger"
                          onClick={() => {
                            const filteredClaims = claimForms.filter(
                              (item) => item.id !== claimForm.id
                            )
                            setClaimForms(filteredClaims)
                            setFieldValue(
                              "claims",
                              filteredClaims.map((item) => item.id)
                            )
                          }}
                          actionName="Delete"
                          iconClass="mdi mdi-close"
                        />
                      )}
                    </div>
                  </td>
                ) : (
                  ""
                )}
                {!recordId && (
                  <td>
                    <SingleRowAction
                      color="text-danger"
                      onClick={() => {
                        const filteredClaims = claimForms.filter(
                          (item) => item.id !== claimForm.id
                        )
                        setClaimForms(filteredClaims)
                        setFieldValue(
                          "claims",
                          filteredClaims.map((item) => item.id)
                        )
                      }}
                      actionName="Delete"
                      iconClass="mdi mdi-close"
                    />
                  </td>
                )}
              </tr>
              {index === claimForms.length - 1 && (
                <tr>
                  <td colSpan={6}></td>
                  <td>
                    {/* sum of total claim amount  */}
                    {claimForms
                      .reduce(
                        (total, claim) =>
                          total + (claim?.total_claimed_amount || 0),
                        0
                      )
                      .toLocaleString()}
                  </td>

                  <td colSpan={2}></td>
                </tr>
              )}
            </Fragment>
          ))}
      </StickyHeaderTable>
    </div>
  )
}

export default withTranslation()(InnerClaimsTable)
